module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-aphrodite/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bride Surprise","short_name":"BrideSurprise","start_url":"/","background_color":"#fdf0f2","theme_color":"#fdf0f2","display":"standalone","icon":"src/img/logo.png","legacy":false,"cache_busting_mode":"none","theme_color_in_head":false,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-85621834-2","head":true,"anonymize":true,"respectDNT":false,"cookieDomain":"bride-surprise.com","exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
